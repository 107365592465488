<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="2" class="my-3">
        <v-btn :to="{ name: route }">Back</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    route: String,
  },
  data() {
    return {
      actual: '',
    }
  },
}
</script>

<style>
</style>
